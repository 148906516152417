<script lang='ts" context="module">
	export const HANDLE_ID = {
		top: "conditional-top-handle",
		left: "conditional-left-handle",
		right: "conditional-right-handle",
	};
</script>

<script lang="ts">
	import {
		Handle,
		Position,
		useNodes,
		useEdges,
		getConnectedEdges,
	} from "@xyflow/svelte";
	import { isEditable } from "./app-state";
	import BaseNode, { type BaseNodeProps } from "./base-node.svelte";
	import { Split } from "lucide-svelte";

	type $$Props = BaseNodeProps;

	$$restProps;

	export let id: $$Props["id"];
	export let data: $$Props["data"];

	const nodes = useNodes();
	const edges = useEdges();



	$: node = $nodes.find((node) => node.id === id);
	$: connected = node ? getConnectedEdges([node], $edges) : [];
	$: targetConnected = connected.some(
		(edge) => edge.target === id && edge.targetHandle === HANDLE_ID.top,
	);
	$: sourceConnectedLeft = connected.some(
		(edge) => edge.source === id && edge.sourceHandle === HANDLE_ID.left,
	);
	$: sourceConnectedRight = connected.some(
		(edge) => edge.source === id && edge.sourceHandle === HANDLE_ID.right,
	);

	$: data.title = "Split";
</script>

<BaseNode icon={Split} iconClass="wpf-rotate-180" {...$$props}>
	<svelte:fragment slot="handles">
		{#if $isEditable || targetConnected}
			<Handle type="target" position={Position.Top} id={HANDLE_ID.top} />
		{/if}

		{#if $isEditable || sourceConnectedLeft}
			<Handle
				type="source"
				position={Position.Bottom}
				style="left: 30px; right:auto;"
				id={HANDLE_ID.left}
			/>
		{/if}

		{#if $isEditable || sourceConnectedRight}
			<Handle
				type="source"
				position={Position.Bottom}
				style="right: 20px; left:auto;"
				id={HANDLE_ID.right}
			/>
		{/if}
	</svelte:fragment>
</BaseNode>
