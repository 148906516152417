<script lang="ts">
	import {
		Handle,
		Position,
		useNodes,
		useEdges,
		getConnectedEdges,
	} from "@xyflow/svelte";
	import { isEditable } from "./app-state";
	import BaseNode, { type BaseNodeProps } from "./base-node.svelte";
	import { Clock } from "lucide-svelte";

	type $$Props = BaseNodeProps;

	$$restProps;

	export let id: $$Props["id"];

	const nodes = useNodes();
	const edges = useEdges();

	$: node = $nodes.find((node) => node.id === id);
	$: connected = node ? getConnectedEdges([node], $edges) : [];
	$: targetConnected = connected.some(
		(edge) =>
			edge.target === id && edge.targetHandle === "delay-target-top-handle",
	);
	$: sourceConnected = connected.some(
		(edge) =>
			edge.source === id && edge.sourceHandle === "delay-source-bottom-handle",
	);
</script>

<BaseNode icon={Clock} {...$$props}>
	<svelte:fragment slot="handles">
		{#if $isEditable || targetConnected}
			<Handle
				type="target"
				position={Position.Top}
				id="delay-target-top-handle"
			/>
		{/if}

		{#if $isEditable || sourceConnected}
			<Handle
				type="source"
				position={Position.Bottom}
				id="delay-source-bottom-handle"
			/>
		{/if}
	</svelte:fragment>
</BaseNode>
