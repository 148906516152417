import type { NodeTypes } from "@xyflow/svelte";
import EmailNode from "./email-node.svelte";
import StartingNode from "./starting-node.svelte";
import EndingNode from "./end-node.svelte";
import ConditionalNode from "./conditional-node.svelte";
import DelayNode from "./delay-node.svelte";

export type NodeData = {
	label: string;
	title?: string;
	body?: string;
};

export const nodeTypes: NodeTypes = {
	emailNode: EmailNode,
	startingNode: StartingNode,
	delayNode: DelayNode,
	conditionalNode: ConditionalNode,
	endingNode: EndingNode,
};

export type NodeType =
	| "emailNode"
	| "startingNode"
	| "endingNode"
	| "conditionalNode"
	| "delayNode";
