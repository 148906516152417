<script lang="ts" context="module">
	import type { NodeData } from "./node-types";
	import { type NodeProps } from "@xyflow/svelte";
	import type { IconProps, IconEvents, IconSlots } from "lucide-svelte";

	export type BaseNodeProps = NodeProps & {
		data: NodeData;
		editableTitle: boolean | undefined;
		editableBody: boolean | undefined;
		iconClass: string | undefined;
		class: string | undefined;
		icon:
			| typeof SvelteComponent<IconProps, IconEvents, IconSlots>
			| typeof SvelteComponent
			| undefined;
	};
</script>

<script lang="ts">
	import { useNodes } from "@xyflow/svelte";
	import NodeWrapper from "./node-wrapper.svelte";
	import { isEditable } from "./app-state";
	import { contenteditable } from "./utils/actions";
	import type { SvelteComponent } from "svelte";

	const nodes = useNodes();

	type $$Props = BaseNodeProps;

	$$restProps;

	let className: string | undefined = undefined;
	export { className as class };
	export let icon: $$Props["icon"];
	export let data: $$Props["data"];
	export let id: $$Props["id"];
	export let iconClass: $$Props["iconClass"];
	export let editableTitle = true;
	export let editableBody = true;

	function handleEditableTitle(e: CustomEvent<string>) {
		e.preventDefault();

		const text = e.detail;

		$nodes.forEach((node) => {
			if (node.id === id) {
				node.data = {
					...node.data,
					title: text,
				};

				$nodes = $nodes;
			}
		});
	}

	function handleEditableBody(e: CustomEvent<string>) {
		e.preventDefault();

		const text = e.detail;

		$nodes.forEach((node) => {
			if (node.id === id) {
				node.data = {
					...node.data,
					body: text,
				};

				$nodes = $nodes;
			}
		});
	}
</script>

<NodeWrapper {icon} {iconClass} class={className}>
	<slot name="handles" />

	{#if $isEditable && editableTitle}
		<span
			class="nodrag editable-title"
			class:has-content={data?.title}
			placeholder={data?.title ? "" : "Schrijf iets"}
			use:contenteditable={{
				debounce: 0,
			}}
			on:contenteditable={handleEditableTitle}
		>
			{#if data?.title}
				{data.title}
			{/if}
		</span>
	{:else}
		<span class="title">{data.title}</span>
	{/if}

	<svelte:fragment slot="body">
		{#if $isEditable && editableBody}
			<span
				class="nodrag editable-title"
				class:has-content={data?.body}
				placeholder={data?.body ? "" : "Schrijf iets"}
				use:contenteditable={{
					debounce: 0,
				}}
				on:contenteditable={handleEditableBody}
			>
				{#if data?.body}
					<div class="wpf-px-3 wpf-pb-3">
						<div
							class="wpf-overflow-hidden wpf-max-w-80 wpf-w-full wpf-text-ellipsis wpf-whitespace-nowrap wpf-text-base wpf-text-primary"
						>
							{data.body}
						</div>
					</div>
				{/if}
			</span>
		{:else if data.body}
			<div class="wpf-px-3 wpf-pb-3">
				<div
					class="wpf-overflow-hidden wpf-max-w-80 wpf-w-full wpf-text-ellipsis wpf-whitespace-nowrap wpf-text-base wpf-text-primary"
				>
					{data.body}
				</div>
			</div>
		{/if}
	</svelte:fragment>
</NodeWrapper>

<style>
	.editable-title {
		display: block;
		min-width: 100%;
		width: 100%;
		outline: 0;
		white-space: pre-wrap;
		word-break: break-word;
		caret-color: rgb(55, 53, 47);
		padding: 3px 2px;
		min-height: 1em;
		color: rgb(55, 53, 47);
		-webkit-text-fill-color: rgba(55, 53, 47, 0.5);
	}

	.editable-title.has-content {
		white-space: pre-wrap;
		word-break: break-word;
		caret-color: black;
		-webkit-text-fill-color: black;
		padding: 3px 2px;
	}

	.editable-title:empty:after {
		content: attr(placeholder);
	}
</style>
