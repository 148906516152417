<script lang="ts">
	import { useNodes } from "@xyflow/svelte";
	import type { NodeType } from "./node-types";

	const nodes = useNodes();

	const availableNodes: {
		type: NodeType;
		label: string;
	}[] = [
		{
			type: "startingNode",
			label: "Startpunt",
		},
		{
			type: "emailNode",
			label: "Email",
		},
		{
			type: "delayNode",
			label: "Delay",
		},
		{
			type: "conditionalNode",
			label: "Conditional",
		},
		{
			type: "endingNode",
			label: "Eindpunt",
		},
	];

	const onDragStart = (event: DragEvent, nodeType: string) => {
		if (!event.dataTransfer) {
			return null;
		}

		event.dataTransfer.setData("application/svelteflow", nodeType);
		event.dataTransfer.effectAllowed = "move";
	};

	$: hasStartingNode = $nodes.some((node) => node.type === "startingNode");
</script>

<aside class="wpflows__sidebar">
	<ul class="wpflows__nodes-container">
		{#each availableNodes.filter(({ type }) => type !== "startingNode" || !hasStartingNode) as { type, label }}
			<li
				class="{type}-node button-secondary"
				on:dragstart={(event) => onDragStart(event, type)}
				draggable={true}
			>
				{label}
			</li>
		{/each}
	</ul>
</aside>

<style>
	.wpflows__sidebar {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.wpflows__nodes-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;
		flex-wrap: nowrap;
	}

	.wpflows__nodes-container li:not(:last-child) {
		margin-right: 1rem;
	}
</style>
