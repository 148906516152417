<script lang="ts">
	import {
		SvelteFlow,
		Background,
		type Node,
		type Edge,
		useSvelteFlow,
		useNodes,
		ConnectionLineType,
		ConnectionMode,
		useEdges,
		type EdgeTypes,
	} from "@xyflow/svelte";
	import { writable } from "svelte/store";
	import Sidebar from "./sidebar.svelte";
	import { appConfig, isEditable } from "./app-state";
	import { nodeTypes, type NodeData } from "./node-types";
	import "@xyflow/svelte/dist/style.css";
	import { api } from "./utils/api";
	import { SETTINGS_HIDDEN_INPUT_ID } from "./constants";
	import { onMount } from "svelte";
	import { debounce } from "./utils/debounce";
	import ConditionalEdge from "./conditional-edge.svelte";
	import { HANDLE_ID } from "./conditional-node.svelte";

	const uNodes = useNodes();
	const uEdges = useEdges();

	const defaultGuestOptions = {
		draggable: $isEditable,
		deletable: $isEditable,
		connectable: $isEditable,
		selectable: $isEditable,
	};

	const nodes = writable<Node<NodeData>[]>(
		$appConfig?.settings
			? $appConfig.settings.nodes.map((node) => ({
					...node,
					...defaultGuestOptions,
				}))
			: [],
	);

	const edges = writable<Edge[]>(
		$appConfig?.settings
			? $appConfig.settings.edges.map((edge) => {
					if (edge.sourceHandle === HANDLE_ID.right) {
						return {
							...edge,
							animated: true,
							label: "Ja",
						};
					}

					if (edge.sourceHandle === HANDLE_ID.left) {
						return {
							...edge,
							animated: true,
							label: "Nee",
						};
					}

					return {
						...edge,
						animated: true,
					};
				})
			: [],
	);

	const edgeTypes: EdgeTypes = {
		conditional: ConditionalEdge,
	};

	const { screenToFlowPosition, toObject } = useSvelteFlow();

	const onDragOver = (event: DragEvent) => {
		event.preventDefault();

		if (event.dataTransfer) {
			event.dataTransfer.dropEffect = "move";
		}
	};

	const onDrop = (event: DragEvent) => {
		event.preventDefault();

		if (!event.dataTransfer) {
			return null;
		}

		const type = event.dataTransfer.getData("application/svelteflow");

		const position = screenToFlowPosition({
			x: event.clientX,
			y: event.clientY,
		});

		const newNode = {
			id: `${Math.random()}`,
			type,
			position,
			data: { label: `${type} node` },
			origin: [0.5, 0.0],
		} satisfies Node;

		$nodes.push(newNode);
		$nodes = $nodes;
	};

	/**
	 * This hidden input is for settings that are saved on the server.
	 */
	const setSettingsInput = () => {
		const input = document.getElementById(
			SETTINGS_HIDDEN_INPUT_ID,
		) as HTMLInputElement | null;

		if (input) {
			input.value = JSON.stringify(toObject());
		}
	};
	const debouncedSetSettingsInput = debounce(setSettingsInput, 500);

	const saveFlow = async () => {
		const flow = toObject();

		await api
			.post<void>("save_settings", {
				postId: $appConfig.postId,
				settings: JSON.stringify(flow),
			})
			.then((response) => {
				if (!response.data) {
					throw new Error("No data returned");
				}

				return response.data;
			});
	};
	const debouncedSaveFlow = debounce(saveFlow, 1000);

	onMount(() => {
		uNodes.subscribe((nodes) => {
			if ($isEditable) {
				debouncedSaveFlow();
				debouncedSetSettingsInput();
			}
		});
		uEdges.subscribe((edges) => {
			if ($isEditable) {
				debouncedSaveFlow();
				debouncedSetSettingsInput();
			}
		});
	});
</script>

<div class="wpflows-flow" style:height={$appConfig.canvasHeight}>
	{#if $isEditable}
		<div class="wpflows-toolbar">
			<div class="wpflows-toolbar__edit">
				<Sidebar />
			</div>
		</div>
	{/if}

	<SvelteFlow
		{nodes}
		{nodeTypes}
		{edges}
		{edgeTypes}
		fitView
		connectionMode={ConnectionMode.Loose}
		snapGrid={[32, 32]}
		proOptions={{ hideAttribution: true }}
		on:dragover={onDragOver}
		on:drop={onDrop}
	>
		<Background />

		<div class="wpflows__panel wpflows__attribution bottom right">
			<a
				href="https://loyalminds.nl?utm_source=flow&utm_medium=attribution&utm_campaign=flow-demo"
				target="_blank"
				aria-label="Loyal Minds attribution"
			>
				Loyal Minds
			</a>
		</div>
	</SvelteFlow>
</div>

<style lang="postcss">
	.wpflows-toolbar {
		width: 100%;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;

		border-bottom: 1px solid #c3c4c7;
		padding: 16px;
		& .wpflows-toolbar__edit {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
		}
	}

	:global(.svelte-flow) {
		border-radius: 32px;
		border: 1px solid #d3d3d3;
	}

	:global(.svelte-flow__background) {
		width: 100%;
	}

	:global(.svelte-flow .svelte-flow__handle) {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: var(--e-global-color-secondary, #7a68e5);
	}

	:global(.svelte-flow__edge-label) {
		@apply wpf-shadow;
		text-align: center;
		position: absolute;
		padding: 2px 7px;
		border-radius: 12px;
		font-size: 10px;
		cursor: pointer;
		color: var(--e-global-color-primary, #13082D);
    	background-color: var(--e-global-color-text, #FADF92);
	}

	.wpflows__panel {
		position: absolute;
		z-index: 5;
		margin: 15px;
		bottom: 0;
		right: 24px;
	}

	.wpflows__attribution {
		font-size: 10px;
		background: #fff;
		padding: 2px 8px;
		border-radius: 6px;
		margin: 0;
		font-weight: bold;
	}

	.wpflows__attribution a {
		text-decoration: none;
		color: black;
	}
</style>
