import type { Edge, Node, Viewport } from "@xyflow/svelte";
import { derived, writable } from "svelte/store";
import type { NodeData } from "./node-types";

export type AppState = {
	postId: string;
	isAdmin: boolean;
	nonce: string;
};

export type ShortcodeConfig = {
	postId: string;
	canvasHeight: number;
	settings: {
		nodes: Node<NodeData>[];
		edges: Edge[];
		viewport: Viewport;
	} | null;
};

export const _appConfig = writable<typeof window.wpFlows>({
	isAdmin: "false",
	isWpAdmin: "false",
	ajaxUrl: "",
	nonce: "",
});

export const _shortcodeConfig = writable<ShortcodeConfig>({
	postId: "",
	canvasHeight: 500,
	settings: null,
});

export const appConfig = derived(
	[_appConfig, _shortcodeConfig],
	([$config, $shortcode]) => {
		return {
			canvasHeight: $shortcode.canvasHeight.toString() + "px",
			settings: $shortcode.settings,
			postId: $shortcode.postId,
			isWpAdmin: $config.isWpAdmin === "true",
			isAdmin: $config.isAdmin === "true",
			nonce: $config.nonce,
		};
	}
);

export const isEditable = derived(appConfig, ($config) => {
	return $config.isAdmin && $config.isWpAdmin;
});
