<script lang="ts">
	import type { SvelteComponent } from "svelte";
	import type { IconProps, IconEvents, IconSlots } from "lucide-svelte";
	import { GripVertical } from "lucide-svelte";
	import { isEditable } from "./app-state";
	import { cn } from "./utils/cn";

	let className: string | undefined = undefined;
	export { className as class };
	export let iconClass: string | undefined = undefined;
	export let icon:
		| typeof SvelteComponent<IconProps, IconEvents, IconSlots>
		| undefined = undefined;
</script>

<div
	class={cn(
		"wpf-flex wpf-min-w-48 wpf-max-w-96 wpf-flex-col wpf-rounded-md wpf-relative wpf-h-full wpf-bg-white wpf-shadow wpf-text-primary",
		className,
	)}
>
	{#if $isEditable}
		<GripVertical
			class="wpf-text-primary wpf-size-4 wpf-absolute wpf-right-0.5 wpf-top-0.5"
		/>
	{/if}

	<div class="wpf-flex wpf-justify-between wpf-items-center wpf-p-3">
		<div class="wpf-flex wpf-items-center">
			{#if icon}
				<div
					class="wpf-inline-flex wpf-items-center wpf-rounded-lg wpf-text-secondary wpf-justify-center wpf-size-8 wpf-bg-zinc-100 wpf-mr-3"
				>
					<svelte:component this={icon} class={cn("wpf-size-5", iconClass)} />
				</div>
			{/if}

			<div
				class="wpf-flex-auto wpf-text-base wpf-font-bold wpf-min-w-0 wpf-flex wpf-flex-col"
			>
				<slot />
			</div>
		</div>
	</div>

	{#if $$slots.body}
		<slot name="body"></slot>
	{/if}
</div>
