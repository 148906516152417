import App from "./app.svelte";

import type { ShortcodeConfig } from "./lib/app-state";

const frontendMount = document.getElementById("wp-flows");

const mountFrontend = () => {
	if (frontendMount) {
		let postId = frontendMount.getAttribute("data-post-id");
		const settings = frontendMount.getAttribute("data-settings");
		const canvasHeight = frontendMount.getAttribute("data-canvas-height");
		if (!postId) {
			console.error("[wp-flows] Could not find post ID to mount on.");

			return;
		}

		new App({
			target: frontendMount,
			props: {
				shortcodeConfig: {
					postId,
					canvasHeight: canvasHeight ? parseInt(canvasHeight) : 500,
					settings: settings
						? (JSON.parse(settings) as ShortcodeConfig["settings"])
						: null,
				},
				appConfig: window.wpFlows,
			},
		});
	} else {
		return;
	}
};

const app = mountFrontend();

export default app;
