<script lang="ts">
	import {
		Handle,
		Position,
		useNodes,
		useEdges,
		getConnectedEdges,
	} from "@xyflow/svelte";
	import { isEditable } from "./app-state";
	import BaseNode, { type BaseNodeProps } from "./base-node.svelte";

	type $$Props = BaseNodeProps;

	$$restProps;

	export let id: $$Props["id"];
	export let data: $$Props["data"];

	const nodes = useNodes();
	const edges = useEdges();

	$: node = $nodes.find((node) => node.id === id);
	$: connected = node ? getConnectedEdges([node], $edges) : [];
	$: targetConneccted = connected.some(
		(edge) => edge.source === id && edge.sourceHandle === "target-top-handle",
	);

	$: data.title = "Einde";
</script>

<BaseNode
	editableTitle={false}
	editableBody={false}
	class="wpf-flex wpf-items-center wpf-justify-center wpf-bg-white !wpf-min-w-20"
	{...$$props}
>
	<svelte:fragment slot="handles">
		<Handle
			type="source"
			id="target-top-handle"
			position={Position.Top}
			isConnectableEnd={true}
		/>
	</svelte:fragment>
</BaseNode>
