<script lang="ts">
	import {
		Handle,
		Position,
		type NodeProps,
		useNodes,
		useEdges,
		getConnectedEdges,
	} from "@xyflow/svelte";
	import { isEditable } from "./app-state";
	import BaseNode, { type BaseNodeProps } from "./base-node.svelte";
	import { Zap, Mail } from "lucide-svelte";

	type $$Props = BaseNodeProps;

	$$restProps;

	export let id: $$Props["id"];
	export let data: $$Props["data"];

	const nodes = useNodes();
	const edges = useEdges();

	// Always be x: 0, y: 0
	$: $nodes.forEach((node) => {
		if (node.id === id) {
			node.position = { x: 0, y: 0 };
		}
	});

	$: node = $nodes.find((node) => node.id === id);
	$: connected = node ? getConnectedEdges([node], $edges) : [];
	$: sourceConnected = connected.some(
		(edge) =>
			edge.source === id && edge.sourceHandle === "source-bottom-handle",
	);

	$: data.title = "Trigger";
</script>

<BaseNode icon={Zap} editableTitle={false} {...$$props}>
	<svelte:fragment slot="handles">
		{#if $isEditable || sourceConnected}
			<Handle
				type="source"
				id="source-bottom-handle"
				position={Position.Bottom}
			/>
		{/if}
	</svelte:fragment>
</BaseNode>
