import { debounce } from "./debounce";
import type { Action } from "svelte/action";

interface ContenteditableAttributes {
	"on:input"?: (event: CustomEvent<KeyboardEvent>) => void;
}

interface ContenteditableParameters {
	/** whether to activate the action. Default to `true` */
	enabled: boolean;
	debounce: number;
	/** options to add to `addEventListener` */
	options?: AddEventListenerOptions | boolean;
	/** whether the element should focus automatically on mount. Default to `false` */
	autoFocus: boolean;
}

export const contenteditable: Action<
	HTMLElement,
	Partial<ContenteditableParameters>
> = function (node, parameters = { enabled: true }) {
	const {
		enabled,
		autoFocus,
		options,
		debounce: debounceTime,
	} = resolveParameters(parameters);

	// Create a debounced function outside the event handler
	let debouncedEmit = debounce((event: Event) => {
		node.dispatchEvent(
			new CustomEvent("contenteditable", { detail: node.innerText })
		);
	}, debounceTime);

	const handleInput = (event: Event) => {
		debouncedEmit(event);
	};

	if (enabled) {
		if (autoFocus) node.focus();
		node.contentEditable = "true";
		node.addEventListener("input", handleInput, options);
	}

	return {
		update(newParameters) {
			const {
				enabled: newEnabled,
				autoFocus: newAutoFocus,
				options: newOptions,
				debounce: newDebounceTime,
			} = resolveParameters(newParameters);

			if (newDebounceTime !== debounceTime) {
				// Re-initialize debounced function if debounce parameter changes
				debouncedEmit = debounce((event: Event) => {
					node.dispatchEvent(
						new CustomEvent("contenteditable", { detail: node.innerText })
					);
				}, newDebounceTime);
			}

			if (newEnabled) {
				if (newAutoFocus) node.focus();
				node.contentEditable = "true";

				node.addEventListener("input", handleInput, newOptions);
			} else {
				node.contentEditable = "false";
				node.removeEventListener("input", handleInput, options);
			}
		},
		destroy() {
			node.contentEditable = "false";
			node.removeEventListener("input", handleInput, options);
		},
	};
};

function resolveParameters(parameters: Partial<ContenteditableParameters>) {
	return {
		enabled: parameters.enabled ?? true,
		autoFocus: parameters.autoFocus ?? false,
		debounce: parameters.debounce ?? 1000,
		options: parameters.options,
		capture:
			typeof parameters.options === "object"
				? parameters.options?.capture
				: parameters.options,
	};
}
